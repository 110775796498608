import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import localstorage from '@utils/localstorage';
import User from '@models/User';
import { AuthState } from './types';

const initialState: AuthState = {
  user: localstorage.exist('user') ? localstorage.getJson('user') : null,
  connected: localstorage.exist('isConnected'),
  telephone: ''
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },

    setAuthUserTelephone: (state, action: PayloadAction<string>) => {
      state.telephone = action.payload;
    },

    removeUser: (state) => {
      state.user = null;
      state.connected = false;
      state.telephone = '';
      localstorage.remove('user');
      localstorage.remove('isConnected');
    },

    saveUser: (state) => {
      localstorage.setJson('user', state.user);
    },

    userConnected: (state) => {
      state.connected = true;
      localstorage.set('isConnected', true);
    },

    userDisconnected: (state) => {
      state.connected = false;
    }
  }
});

export const {
  setAuthUser,
  removeUser,
  userConnected,
  userDisconnected,
  setAuthUserTelephone,
  saveUser
} = authSlice.actions;

export default authSlice.reducer;
