const localStorage = window.localStorage;

const prefix = 'eurazeo_takeon_';

class LocalStorage {
  exist(key: string) {
    if (
      localStorage.getItem(prefix + key) &&
      localStorage.getItem(prefix + key) !== 'undefined'
    ) {
      return true;
    }
    return false;
  }

  getJson(key: string) {
    if (this.exist(key)) {
      return JSON.parse(localStorage.getItem(prefix + key) || '{}');
    }
    return false;
  }

  setJson(key: string, data: any) {
    localStorage.setItem(prefix + key, JSON.stringify(data));
  }

  get(key: string) {
    if (this.exist(key)) {
      return localStorage.getItem(prefix + key);
    }
    return null;
  }

  set(key: string, data: any) {
    localStorage.setItem(prefix + key, data);
  }

  remove(key: string) {
    localStorage.removeItem(prefix + key);
  }

  clear() {
    localStorage.clear();
  }
}

export default new LocalStorage();
