import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Souscription from '@models/Souscription';
import SnvestissementState from './types';

const initialState: SnvestissementState = {
  data: [],
  count: 0
};

export const investissementSlice = createSlice({
  name: 'investissement',
  initialState,
  reducers: {
    setInvestissements: (state, action: PayloadAction<Souscription[]>) => {
      state.data = action.payload;
      state.count = state.data.length;
    },

    addInvestissement: (state, action: PayloadAction<Souscription>) => {
      state.data.push(action.payload);
      state.count = state.data.length;
    },

    removeInvestissement: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(
        (investissement) => investissement.id !== action.payload
      );
      state.count = state.data.length;
    },

    updateInvestissement: (state, action: PayloadAction<Souscription>) => {
      state.data = state.data.map((investissement) =>
        investissement.id === action.payload.id
          ? action.payload
          : investissement
      );
    }
  }
});

export const {
  setInvestissements,
  addInvestissement,
  removeInvestissement,
  updateInvestissement
} = investissementSlice.actions;

export default investissementSlice.reducer;
