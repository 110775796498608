import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import { store } from '@store';
import { setInvestors } from '@store/investor/reducer';
import ApiResponse from '@models/ApiResponse';
import Investor, { InvestorForm } from '@models/Investor';
import i18next from '@/i18n';

const source = 'investors';

class InvestorService {
  async load(): Promise<Investor[]> {
    const rs: AxiosResponse<ApiResponse<Investor[]>> = await http.get(
      `${source}`
    );
    store.dispatch(setInvestors(rs.data.data));
    return rs.data.data;
  }

  async reload(): Promise<Investor[]> {
    let data: Investor[] = store.getState().investor.data;
    data = await this.load();
    return data;
  }

  async all(): Promise<Investor[]> {
    const toastLoading = toast.loading(i18next.t('toast.loadInvestments'));
    try {
      const data = await this.reload();
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async refresh(): Promise<Investor[]> {
    const toastLoading = toast.loading(
      i18next.t('toast.reloadInvestments').toString()
    );
    try {
      const data: Investor[] = await this.load();
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async store(data: InvestorForm): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.addInfoInvestor'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(`${source}`, data);
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      await this.refresh();
      return rs.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async update(data: InvestorForm, id: string): Promise<any> {
    const toastLoading = toast.loading(
      i18next.t('toast.updateInfoInvestor').toString()
    );
    try {
      const rs: AxiosResponse<ApiResponse> = await http.put(
        `${source}/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });

      return rs.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async show(id: string): Promise<Investor> {
    const toastLoading = toast.loading(i18next.t('toast.loadInvestor'));
    try {
      const rs: AxiosResponse<ApiResponse<Investor>> = await http.get(
        `${source}/${id}/details`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async one(id?: string): Promise<Investor> {
    const rs: AxiosResponse<ApiResponse<Investor>> = await http.get(
      `${source}/${id}/details`
    );
    return rs.data.data;
  }

  async getInvestor(): Promise<any> {
    const rs: AxiosResponse<ApiResponse> = await http.get(`${source}`);
    return rs.data.data;
  }
}

export default new InvestorService();
