import { AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import ApiResponse from '@models/ApiResponse';
import { CountryType } from '@models/Country';

const source = 'countries';

class CountryService {
  async all(): Promise<CountryType[]> {
    // const toastLoading = toast.loading('Vérification du siret ...');
    const rs: AxiosResponse<ApiResponse<any>> = await http.get(`${source}`);
    // toast.dismiss(toastLoading);
    return rs.data.data;
  }
}

export default new CountryService();
