import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import userReducer from './user/reducer';
import authReducer from './auth/reducer';
import investorReducer from './investor/reducer';
import loaderReducer from './loader/reducer';
import souscriptionReducer from './souscription/reducer';
import investissementReducer from './investissement/reducer';
import documentReducer from './document/reducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    loader: loaderReducer,
    investor: investorReducer,
    souscription: souscriptionReducer,
    investissement: investissementReducer,
    document: documentReducer
  }
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
