import { useState, useEffect } from 'react';
import debounce from 'debounce';
import {
  LG_MIN,
  MD_MIN,
  SM_MIN,
  XL_MIN,
  XXL_MIN
} from '@constants/breakpoints';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const SIZE_XS: Breakpoint = 'xs';
export const SIZE_SM: Breakpoint = 'sm';
export const SIZE_MD: Breakpoint = 'md';
export const SIZE_LG: Breakpoint = 'lg';
export const SIZE_XL: Breakpoint = 'xl';
export const SIZE_XXL: Breakpoint = 'xxl';

const resolveBreakpoint = (
  width: number
): { device: Breakpoint; width: number } => {
  if (width < SM_MIN) {
    return { device: SIZE_XS, width };
  } else if (width >= SM_MIN && width < MD_MIN) {
    return { device: SIZE_SM, width };
  } else if (width >= MD_MIN && width < LG_MIN) {
    return { device: SIZE_MD, width };
  } else if (width >= LG_MIN && width < XL_MIN) {
    return { device: SIZE_LG, width };
  } else if (width >= XL_MIN && width < XXL_MIN) {
    return { device: SIZE_XL, width };
  } else {
    return { device: SIZE_XXL, width };
  }
};

const useBreakpoint = (): { device: Breakpoint; width: number } => {
  const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = debounce(function () {
      setSize(resolveBreakpoint(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return size;
};

export default useBreakpoint;
