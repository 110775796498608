import toast from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import { store } from '@store';
import ApiResponse from '@models/ApiResponse';
import i18next from '@/i18n';

const source = 'mail';

class MailService {
  async contact(subject: string, comment: string): Promise<void> {
    const toastLoading = toast.loading(i18next.t('toast.sendingEmail'));

    if (!store.getState().user.profile) {
      toast.error(i18next.t('toast.notIdentify'), {
        id: toastLoading
      });
      throw 'Non identifié';
    }

    const data = {
      subject,
      comment,
      // email: store.getState().user.profile?.email,
      email: process.env.REACT_APP_CONTACT_MAIL_SENDER,
      name: `${store.getState().user.profile?.firstname} ${
        store.getState().user.profile?.lastname
      }`,
      contact: `${store.getState().user.profile?.firstname} ${
        store.getState().user.profile?.lastname
      }, pour l'investisseur ${store.getState().auth.user?.investor.name}`
    };

    try {
      const rs: AxiosResponse<ApiResponse<any>> = await http.post(
        `${source}/contact`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new MailService();
