import toast from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import { store } from '@store';
import { setUsers, setUserProfile } from '@store/user/reducer';
import ApiResponse from '@models/ApiResponse';
import User, { UserForm } from '@models/User';
import i18next from '@/i18n';

const source = 'users';

class UserService {
  async load(): Promise<User[]> {
    const rs: AxiosResponse<ApiResponse<User[]>> = await http.get(`${source}`);
    store.dispatch(setUsers(rs.data.data));
    return rs.data.data;
  }

  async loadFromInvestor(investor: string): Promise<User[]> {
    const rs: AxiosResponse<ApiResponse<User[]>> = await http.get(source, {
      params: new URLSearchParams({ investor })
    });
    return rs.data.data;
  }

  async reload(): Promise<User[]> {
    let data: User[] = store.getState().user.data;
    if (data.length <= 0) {
      data = await this.load();
    }
    return data;
  }

  async all(): Promise<User[]> {
    const toastLoading = toast.loading(i18next.t('toast.loadingUsers'));
    try {
      const data = await this.reload();
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async refresh(): Promise<User[]> {
    const toastLoading = toast.loading(i18next.t('toast.reloadingUsers'));
    try {
      const data: User[] = await this.load();
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async store(data: UserForm, investor?: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.savingUserInfo'));

    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/${investor || store.getState().auth.user?.investor.id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      await this.refresh();
      return rs.data.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async storeInInvestor(investor: string, data: UserForm): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.savingUserInfo'));

    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/bo/${investor}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async update(data: UserForm, id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.updatingUserInfo'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.put(
        `${source}/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      await this.refresh();
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async updateBO(data: UserForm, id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.updatingUserInfo'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.put(
        `${source}/bo/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async updateMany(users: object): Promise<any> {
    const toastLoading = toast.loading(
      i18next.t('toast.updatingBeneficiaryInfo')
    );
    try {
      const usersInArray = Object.entries(users).map(([id, data], i) => ({
        id: id,
        data: data
      }));

      for (let i = 0; i < usersInArray.length; i++) {
        const user = usersInArray[i];
        await http.put(`${source}/${user.id}`, user.data);
      }

      toast.success(i18next.t('toast.beneficiariesHaveBeenUpdated'), {
        id: toastLoading
      });

      return 'success';
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async updatePassWord(data: UserForm, id: string): Promise<any> {
    const toastLoading = toast.loading(
      i18next.t('toast.updatePasswordOf', { data: data })
    );
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/update/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async delete(id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.deletingUser'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.delete(
        `${source}/${id}`
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      await this.refresh();
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async deleteBO(id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.deletingUser'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.delete(
        `${source}/${id}`
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async loadUser(id: string): Promise<User> {
    const rs: AxiosResponse<ApiResponse<User>> = await http.get(
      `${source}/${id}/details`
    );
    return rs.data.data;
  }

  async show(id: string): Promise<User> {
    const toastLoading = toast.loading(i18next.t('toast.loadingUserInfos'));
    try {
      const rs: AxiosResponse<ApiResponse<User>> = await http.get(
        `${source}/${id}/details`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async profile(): Promise<User> {
    // const toastLoading = toast.loading("Chargement du profil...");
    const rs: AxiosResponse<ApiResponse<User>> = await http.get(
      `${source}/${store.getState().auth.user?.id}/details`
    );
    store.dispatch(setUserProfile(rs.data.data));
    // toast.dismiss(toastLoading);
    const profile = store.getState().user.profile;
    if (!profile) {
      toast.error(i18next.t('toast.accountNotExist'));
      throw 'Compte inexistant';
    } else {
      return profile;
    }
  }

  async loadProfile(): Promise<User> {
    try {
      const rs: AxiosResponse<ApiResponse<User>> = await http.get(
        `${source}/${store.getState().auth.user?.id}/details`
      );
      store.dispatch(setUserProfile(rs.data.data));
      const profile = store.getState().user.profile;
      if (!profile) {
        throw "L'utilisateur n'existe pas";
      } else {
        return profile;
      }
    } catch (error) {
      const err = error as AxiosError<ApiResponse>;
      toast.error(
        i18next.t(`serverMessages.${err.response?.data.message ?? ''}`)
      );
      throw error;
    }
  }
}

export default new UserService();
