import toast from 'react-hot-toast';
import { AxiosResponse, AxiosError } from 'axios';

import http from '@utils/api-http-client';
import ApiResponse from '@models/ApiResponse';
import Document from '@models/Document';
import i18next from '@/i18n';

const source = 'documents';

class DocumentService {
  async download(id: string): Promise<Document> {
    const toastLoading = toast.loading(
      i18next.t('toast.downloadDoc').toString()
    );
    try {
      const rs: AxiosResponse<ApiResponse<Document>> = await http.get(
        `${source}/${id}/download`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async passport(data: any): Promise<Document> {
    const toastLoading = toast.loading(
      i18next.t('toast.getInfoPassport').toString()
    );
    try {
      const rs: AxiosResponse<ApiResponse<Document>> = await http.post(
        `/documents/passport`,
        data
      );
      toast.dismiss(toastLoading);
      const result: any = rs.data.data;
      if (result['Compatibility Error']) {
        toast.error(result['Compatibility Error'] ?? '', {
          id: toastLoading
        });
      }
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new DocumentService();
