export interface Language {
  code: string;
  codeUpper: string;
  name: string;
  flagUrl: string;
}

export const languages: Language[] = [
  {
    code: 'en',
    codeUpper: 'EN',
    name: 'English',
    flagUrl:
      'https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg'
  }
  // {
  //   "code": "fr",
  //   "codeUpper": "FR",
  //   "name": "French",
  //   "flagUrl": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg"
  // }
];
