export const XS_MAX = 575.98;

export const SM_MIN = 576;
export const SM_MAX = 767.98;

export const MD_MIN = 768;
export const MD_MAX = 991.98;

export const LG_MIN = 992;
export const LG_MAX = 1199.98;

export const XL_MIN = 1200;
export const XL_MAX = 1399.98;

export const XXL_MIN = 1400;
