import axios from 'axios';
import toast from 'react-hot-toast';

import i18next from '@/i18n';
import local from './localstorage';

const ignorePath = ['auth/check/token'];

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  (rs: any) => {
    const token = local.get('x-api-key');
    if (token !== null) {
      rs.headers.common.Authorization = `Bearer ${token}`;
    }
    return rs;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (rs: any) => {
    return rs;
  },
  (error: any) => {
    console.error(error.response);

    const pathIgnore = ignorePath.some((path) =>
      (error?.response?.config?.url || '').includes(path)
    );

    if (error.response && error.response.status === 401 && !pathIgnore) {
      local.remove('user');
      local.remove('isConnected');
      window.location.href = `${process.env.REACT_APP_BASE_HREF}`;
    } else {
      const err =
        error.response?.data?.error?.message ||
        error.response?.data?.error ||
        error.response?.data?.message;

      toast.error(
        i18next.t(`serverMessages.${err}`) ||
          i18next.t('toast.errorOccurred').toString()
      );
    }

    return Promise.reject(error);
  }
);

export default instance;
