import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import ApiResponse from '@models/ApiResponse';
import i18next from '@/i18n';

const source = 'funds';

class FundService {
  async load(idFund: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.fundInfo'));
    try {
      const rs: AxiosResponse<ApiResponse<any>> = await http.get(
        `${source}/${idFund}`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async loadDocuments(idFund: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.fundDoc'));
    try {
      const rs: AxiosResponse<ApiResponse<any>> = await http.get(
        `${source}/${idFund}/documents`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new FundService();
