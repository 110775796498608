import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Investor from '@models/Investor';
import InvestorState from './types';

const initialState: InvestorState = {
  data: [],
  count: 0
};

export const investorSlice = createSlice({
  name: 'investor',
  initialState,
  reducers: {
    setInvestors: (state, action: PayloadAction<Investor[]>) => {
      state.data = action.payload;
      state.count = state.data.length;
    },

    addInvestor: (state, action: PayloadAction<Investor>) => {
      state.data.push(action.payload);
      state.count = state.data.length;
    },

    removeInvestor: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter(
        (investor) => investor.id !== action.payload
      );
      state.count = state.data.length;
    },

    updateInvestor: (state, action: PayloadAction<Investor>) => {
      state.data = state.data.map((investor) =>
        investor.id === action.payload.id ? action.payload : investor
      );
    }
  }
});

export const { setInvestors, addInvestor, removeInvestor, updateInvestor } =
  investorSlice.actions;

export default investorSlice.reducer;
