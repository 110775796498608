import toast from 'react-hot-toast';
import { AxiosResponse, AxiosError } from 'axios';

import http from '@utils/api-http-client';
import { store } from '@store';
import { setInvestissements } from '@store/investissement/reducer';
import ApiResponse from '@models/ApiResponse';
import Souscription from '@models/Souscription';
import i18next from '@/i18n';

const source = 'investments';

class InvestissementService {
  async load(): Promise<Souscription[]> {
    const rs: AxiosResponse<ApiResponse<Souscription[]>> = await http.get(
      `${source}`
    );
    store.dispatch(setInvestissements(rs.data.data));
    return rs.data.data;
  }

  async all(): Promise<Souscription[]> {
    const toastLoading = toast.loading(i18next.t('toast.loadInvestments'));
    let data: Souscription[] = store.getState().investissement.data;
    try {
      if (data.length <= 0) {
        data = await this.load();
      }
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async refresh(): Promise<Souscription[]> {
    const toastLoading = toast.loading(
      i18next.t('toast.reloadInvestments').toString()
    );
    try {
      const data: Souscription[] = await this.load();
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new InvestissementService();
