import { SVGAttributes } from 'react';

import { WHITE } from '@constants/colors';

interface LogoProps extends SVGAttributes<HTMLOrSVGElement> {
  brand?: string;
  classLogo?: string;
  classBrand?: string;
  color?: string;
}

const Logo = ({
  classLogo = '',
  classBrand = '',
  brand,
  color = WHITE,
  ...args
}: LogoProps) => {
  return (
    <div className="logo">
      <svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 540.3 239.9"
        xmlSpace="preserve"
        {...args}
      >
        <g>
          <rect
            x="18.9"
            y="49"
            style={{ fill: 'none' }}
            width="557.4"
            height="141.8"
          />
          <g>
            <path
              style={{ fill: color }}
              d="M403.2,96.1h35.4c1.2,0,2.1-1,2.1-2.1v-7.4c0-1.2-0.9-2.1-2.1-2.1h-44.9c-1.2,0-2.1,0.9-2.1,2.1v66.7
			c0,1.2,0.9,2.1,2.1,2.1h45.8c1.2,0,2.1-1,2.1-2.1v-7.4c0-1.2-0.9-2.1-2.1-2.1h-36.3v-18.4h29.4v-4.7c0-3.8-3.1-6.9-6.9-6.9h-22.5
			V96.1z"
            />
            <path
              style={{ fill: color }}
              d="M101.5,96.1h35.4c1.2,0,2.1-1,2.1-2.1v-7.4c0-1.2-0.9-2.1-2.1-2.1H92c-1.2,0-2.1,0.9-2.1,2.1v66.7
			c0,1.2,0.9,2.1,2.1,2.1h45.8c1.2,0,2.1-1,2.1-2.1v-7.4c0-1.2-0.9-2.1-2.1-2.1h-36.3v-18.4h29.4v-4.7c0-3.8-3.1-6.9-6.9-6.9h-22.5
			V96.1z"
            />
            <path
              style={{ fill: color }}
              d="M201.3,84.5h-7.4c-1.2,0-2.1,1-2.1,2.1v41.5c0,9.7-7.9,17.6-17.6,17.6c-9.7,0-17.6-7.9-17.6-17.6V86.6
			c0-1.2-1-2.1-2.1-2.1H147c-1.2,0-2.1,1-2.1,2.1v41.5c0,16.1,13.1,29.2,29.2,29.3h0c16.1,0,29.2-13.1,29.2-29.3V86.6
			C203.4,85.4,202.4,84.5,201.3,84.5z"
            />
            <path
              style={{ fill: color }}
              d="M441.6,125.5c0,17.6,14.3,31.9,31.9,31.9s31.9-14.3,31.9-31.9v-11.1c0-17.6-14.3-31.9-31.9-31.9
			s-31.9,14.3-31.9,31.9V125.5z M453.7,114.4c0-11.1,8.7-19.8,19.8-19.8s19.8,8.8,19.8,19.8v11.1c0,11.1-8.7,19.8-19.8,19.8
			s-19.8-8.8-19.8-19.8V114.4z"
            />
            <g>
              <path
                style={{ fill: color }}
                d="M385.4,94.3v-7.7c0-1.2-0.9-2.1-2.1-2.1h-50V89c0,4,3.2,7.2,7.2,7.2H370l-32.1,42.7l-30.6-54.3
				c-0.8-1.4-2.4-2.3-4-2.3c-1.6,0-3.2,0.9-4,2.3l0,0l-31.1,55.1l-9.8-13.4c-0.6-0.9-1.2-1.6-1.7-2.1c7.5-4.2,11.2-11.5,11.1-18.6
				c-0.1-10.1-8.4-21-26.1-21h-30.4v68.8c0,1.2,0.9,2.1,2.1,2.1h7.4c1.2,0,2.1-1,2.1-2.1v-25.4h19.3c1.5,0,2-0.1,3.2-0.2l19.6,27.8
				h7.7l30.6-54.6l30.6,54.6h49.5c1.2,0,2.1-1,2.1-2.1v-7.4c0-1.2-0.9-2.1-2.1-2.1H349L385,95.5C385.3,95.2,385.4,94.7,385.4,94.3z
				 M242.1,116.2h-19.3V96.1h18.8c9.9,0,14.4,4.8,14.5,9.6C256.2,110.6,251.8,115.9,242.1,116.2z"
              />
              <path
                style={{ fill: color }}
                d="M315,137c-2-5.4-5-8-9.5-8.2h0l0,0c-1.5-0.1-3.1-0.1-4.5,0c0,0,0,0,0,0h0c-4.5,0.2-7.5,2.8-9.5,8.2
				c-0.5,1.3-0.9,2.6-0.9,2.6h25.3C315.9,139.6,315.5,138.3,315,137z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
