import toast from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import { store } from '@store';
import {
  saveUser,
  setAuthUser,
  removeUser,
  setAuthUserTelephone,
  userConnected
} from '@store/auth/reducer';
import ApiResponse from '@models/ApiResponse';
import User from '@models/User';
import local from '@utils/localstorage';
import i18next from '@/i18n';

const source = 'auth';
// const { t } = useTranslation();
class AuthService {
  async login(credentials: any): Promise<User> {
    const toastLoading = toast.loading(i18next.t('toast.loadingAuth'));
    try {
      const rs: AxiosResponse<ApiResponse<{ user: User; token: string }>> =
        await http.post(`${source}/login`, credentials);
      const { user, token } = rs.data.data;
      local.set('x-api-key', token);
      store.dispatch(setAuthUser(user));

      // store.dispatch(saveUser());
      // store.dispatch(userConnected());

      await this.request2FA(user.id);

      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return user;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async loginWithToken(token: string): Promise<User> {
    const toastLoading = toast.loading(
      i18next.t('toast.loadingAuth').toString()
    );
    try {
      const rs: AxiosResponse<
        ApiResponse<{ user: User; accessToken: string }>
      > = await http.get(`${source}/check/token/${token}`);
      const { user, accessToken } = rs.data.data;
      local.set('x-api-key', accessToken);
      store.dispatch(setAuthUser(user));
      store.dispatch(saveUser());

      store.dispatch(userConnected());
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return user;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message || "", {
      //   id: toastLoading,
      // });
      // throw (err);
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async request2FA(userId: string): Promise<any> {
    const rs: AxiosResponse<ApiResponse> = await http.get(
      `${source}/request/2fa/${userId}`
    );

    toast.success(i18next.t('toast.code2fa').toString());
    return rs;
  }

  async forgotPassword(email: string): Promise<any> {
    const toastLoading = toast.loading(
      i18next.t('toast.pleaseWait').toString()
    );
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/forgot/password`,
        { email }
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      local.set('reset-user-id', rs.data.data.id);
      return rs;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message || "Une erreur s'est produite", {
      //   id: toastLoading,
      // });
      // throw (error);
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async resetPassword(
    userId: string,
    password: string,
    token: string
  ): Promise<any> {
    const data = {
      password,
      token
    };
    const toastLoading = toast.loading(
      i18next.t('toast.updatePassword').toString()
    );
    try {
      const rs: AxiosResponse<ApiResponse> = await http.put(
        `${source}/reset/password/${userId}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data.message;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message || "Une erreur s'est produite", {
      //   id: toastLoading,
      // });
      // throw (error);
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async validate2FA(userId: string, code: string | number): Promise<any> {
    const data = {
      code
    };
    const toastLoading = toast.loading(
      i18next.t('toast.progress2fa').toString()
    );
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/validate/2fa/${userId}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      store.dispatch(saveUser());
      store.dispatch(userConnected());
      return rs.data.message;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? "", {
      //   id: toastLoading,
      // });
      // throw (error);
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async logout(): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.logout').toString());
    try {
      await http.post(`${source}/logout`);
      store.dispatch(removeUser());
      return 'User logout';
    } finally {
      toast.dismiss(toastLoading);
    }
  }
}

export default new AuthService();
