import { languages } from '@utils/languages';
import localstorage from '@utils/localstorage';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Header.scss';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const preferredLanguage = localstorage.get('preferred-language');

  const getBrowserLang = () => {
    const browserLang = [navigator.language].flat()[0].split('-')[0];
    const isBrowserLangInList = languages.find(
      ({ code }) => code === browserLang
    );

    return isBrowserLangInList ? browserLang : 'en';
  };

  const updatePreferredLanguage = (code: string) => {
    // localstorage.set('preferred-language', code);
    setSelectedLanguage(code);
    i18n.changeLanguage(code);
  };

  useEffect(() => {
    // if (!preferredLanguage) {
    //   preferredLanguage = getBrowserLang();
    // }
    updatePreferredLanguage('en');
  }, [selectedLanguage]);

  const handleDropdownToggle = (nextShow: boolean) => {
    setShowDropdownMenu(nextShow);
  };

  const currentLang = languages?.find((l) => l.code === selectedLanguage);

  return (
    <Dropdown className="language-dropdown" onToggle={handleDropdownToggle}>
      <Dropdown.Toggle className="language-dropdown__toggle" variant="light">
        <div className="language-dropdown__toggle__img-container">
          {selectedLanguage.length >= 2 ? (
            <img
              src={currentLang?.flagUrl}
              alt={currentLang?.name}
              width={20}
            />
          ) : (
            'Please select a language'
          )}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        bsPrefix="language-dropdown__menu__wrapper"
        style={{ overflow: 'hidden' }}
        show={showDropdownMenu}
      >
        <div
          className="language-dropdown__menu__wrapper__container"
          style={{ display: showDropdownMenu ? 'block' : 'none' }}
        >
          {languages.map(({ code, name, flagUrl }, index) => (
            <Dropdown.Item
              key={index}
              className="d-flex gap-1 my-2"
              onClick={() => {
                updatePreferredLanguage(code);
                setShowDropdownMenu(false);
              }}
            >
              <div className="language-dropdown__menu__item mx-2">
                <img src={flagUrl} alt={name} title={name} width={35} />
              </div>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageDropdown;
