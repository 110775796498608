import { Fragment, HTMLAttributes } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/fr';
import { defaultDateFormat } from '@constants/format';

interface DateFormatProps extends HTMLAttributes<HTMLDivElement> {
  date: string | number;
  format?: string;
  unix?: boolean;
}

const DateFormat = ({
  date,
  format = defaultDateFormat,
  unix = false
}: DateFormatProps) => {
  return (
    <Fragment>
      {date && <Moment locale="fr" unix={unix} format={format} date={date} />}
    </Fragment>
  );
};

export default DateFormat;
