import toast from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import ApiResponse from '@models/ApiResponse';
import i18next from '@/i18n';

const source = 'siret';

class SiretService {
  async check(data: any): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.siretCheck'));
    try {
      const rs: AxiosResponse<ApiResponse<any>> = await http.post(
        `${source}`,
        data
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new SiretService();
