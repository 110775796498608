import { Helmet } from 'react-helmet-async';

import i18next from '@/i18n';

const Page404 = () => (
  <div className="page-404">
    <Helmet>
      <title>{i18next.t('titles.404_message')}</title>
    </Helmet>
  </div>
);

export default Page404;
