import { createSlice } from '@reduxjs/toolkit';

import { LoaderState } from './types';

const initialState: LoaderState = {
  loading: false
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    start: (state) => {
      state.loading = true;
    },

    stop: (state) => {
      state.loading = false;
    }
  }
});

export const { start, stop } = loaderSlice.actions;

export default loaderSlice.reducer;
