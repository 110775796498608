import { PropsWithChildren } from 'react';

import './Tooltip.scss';
import {
  OverlayTrigger,
  Tooltip as BootstrapTooltip,
  OverlayTriggerProps
} from 'react-bootstrap';

interface TooltipProps {
  content?: string;
  placement?: OverlayTriggerProps['placement'];
  size?: string;
  className?: string;
}

const Tooltip = ({
  content,
  children,
  placement = 'top',
  className
}: PropsWithChildren<TooltipProps>) => {
  if (!content) {
    return <>{children}</>;
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <BootstrapTooltip className="tooltip" id="tooltip">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </BootstrapTooltip>
      }
    >
      <div className={`cursor-pointer ${className}`}>{children}</div>
    </OverlayTrigger>
  );
};

export default Tooltip;
