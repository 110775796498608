import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Souscription from '@models/Souscription';
import SouscriptionState from './types';

const initialState: SouscriptionState = {
  ongoing: [],
  prepared: [],
  countOngoing: 0,
  countPrepared: 0,
  loadingOngoing: false,
  loadingPrepared: false,
  startedPrepared: ''
};

export const souscriptionSlice = createSlice({
  name: 'souscription',
  initialState,
  reducers: {
    setOngoingSouscriptions: (state, action: PayloadAction<Souscription[]>) => {
      state.ongoing = action.payload.filter(
        (souscription) =>
          !(
            souscription.isOriginal &&
            (souscription.status.id === 'sst-1' ||
              souscription.status.id === 'sst-2')
          )
      );
      state.countOngoing = state.ongoing.length;
    },

    setPraparedSouscriptions: (
      state,
      action: PayloadAction<Souscription[]>
    ) => {
      state.prepared = action.payload.filter(
        (souscription) =>
          souscription.isOriginal &&
          (souscription.status.id === 'sst-1' ||
            souscription.status.id === 'sst-2')
      );
      state.countPrepared = state.prepared.length;
    },

    setOngoingLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingOngoing = action.payload;
    },

    setPraparedLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingPrepared = action.payload;
    },

    setPreparedStarted: (state, action: PayloadAction<string>) => {
      state.startedPrepared = action.payload;
    }
  }
});

export const {
  setOngoingSouscriptions,
  setPraparedSouscriptions,
  setOngoingLoading,
  setPraparedLoading,
  setPreparedStarted
} = souscriptionSlice.actions;

export default souscriptionSlice.reducer;
