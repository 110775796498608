import React, { FC } from 'react';
import { Modal, ModalBody, ModalProps, ModalTitle } from 'react-bootstrap';

interface DialogProps extends ModalProps {
  title: string;
  yesText: string;
  noText: string;
  onSubmit: () => void;
}

export const Dialog: FC<DialogProps> = ({
  title,
  yesText,
  noText,
  onSubmit,
  children,
  ...props
}) => {
  return (
    <Modal {...props}>
      <ModalBody>
        <ModalTitle className="text-black fw-600 mb-4">{title}</ModalTitle>

        {children}

        <div className="d-flex justify-content-end align-items-center gap-3 mt-4">
          <button onClick={props.onHide} className="btn btn-secondary">
            {noText}
          </button>
          <button onClick={onSubmit} className="btn btn-primary">
            {yesText}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
