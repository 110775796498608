import { HTMLAttributes } from 'react';

import './Title.scss';

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
}

const Title = ({
  title,
  children,
  className = 'w-100',
  ...args
}: TitleProps) => {
  return (
    <div className={`title text-center fs-3 fw-600 ${className}`} {...args}>
      {title}
      {children}
    </div>
  );
};

export default Title;
