import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import User from '@models/User';
import UserState from './types';

const initialState: UserState = {
  data: [],
  profile: null,
  count: 0
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.data = action.payload;
      state.count = state.data.length;
    },

    setUserProfile: (state, action: PayloadAction<User>) => {
      state.profile = action.payload;
    },

    addUser: (state, action: PayloadAction<User>) => {
      state.data.push(action.payload);
      state.count = state.data.length;
    },

    removeUser: (state, action: PayloadAction<string>) => {
      state.data = state.data.filter((user) => user.id !== action.payload);
      state.count = state.data.length;
    },

    updateUser: (state, action: PayloadAction<User>) => {
      state.data = state.data.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    }
  }
});

export const { setUsers, addUser, removeUser, updateUser, setUserProfile } =
  userSlice.actions;

export default userSlice.reducer;
