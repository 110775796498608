import toast from 'react-hot-toast';
import { AxiosError, AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import ApiResponse from '@models/ApiResponse';
import i18next from '@/i18n';

const source = 'signers';

class SignataireService {
  async store(data: any, id: any): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.signerInfo'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async storeMultiple(data: any[], id: any): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.signerInfoSave'));
    try {
      for await (const signer of data) {
        await http.post(`${source}/${id}`, signer);
      }
      // const rs: AxiosResponse<ApiResponse> = await http.post(`${source}/${id}`, data);
      toast.success(i18next.t('toast.saveSuccess'), {
        id: toastLoading
      });
      return 'Enregistrement effectué.';
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async update(data: any, id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.signerInfoUpdate'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.put(
        `${source}/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async delete(id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.signerDelete'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.delete(
        `${source}/${id}`
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      return rs.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new SignataireService();
