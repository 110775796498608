import toast from 'react-hot-toast';
import { AxiosResponse } from 'axios';

import http from '@utils/api-http-client';
import { store } from '@store';
import {
  setOngoingSouscriptions,
  setPraparedSouscriptions,
  setOngoingLoading,
  setPraparedLoading
} from '@store/souscription/reducer';
import ApiResponse from '@models/ApiResponse';
import Souscription, {
  SouscriptionForm,
  SouscriptionSplit
} from '@models/Souscription';
import Contrat from '@models/Contrat';
import i18next from '@/i18n';

const source = 'subscriptions';

const contrat = 'contracts';

class SouscriptionService {
  async load(): Promise<Souscription[]> {
    store.dispatch(setOngoingLoading(true));
    store.dispatch(setPraparedLoading(true));
    try {
      const rs: AxiosResponse<ApiResponse<Souscription[]>> = await http.get(
        `${source}`,
        { params: new URLSearchParams({ long: 'false' }) }
      );
      store.dispatch(setOngoingSouscriptions(rs.data.data));
      store.dispatch(setPraparedSouscriptions(rs.data.data));
      return rs.data.data;
    } finally {
      store.dispatch(setOngoingLoading(false));
      store.dispatch(setPraparedLoading(false));
    }
  }

  async get(id: string): Promise<Souscription[]> {
    const rs: AxiosResponse<ApiResponse<Souscription[]>> = await http.get(
      `${source}/${id}/info`
    );
    return rs.data.data;
  }

  async reload(): Promise<{
    ongoing: Souscription[];
    prepared: Souscription[];
  }> {
    const toastLoading = toast.loading(i18next.t('toast.loadingSouscription'));
    const ongoing: Souscription[] = store.getState().souscription.ongoing;
    const prepared: Souscription[] = store.getState().souscription.prepared;
    try {
      if (ongoing.length <= 0 || prepared.length <= 0) {
        await this.load();
      }
      toast.dismiss(toastLoading);
      return { ongoing, prepared };
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async getContratAll(id: string | undefined): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.loadingContracts'));
    const rs: AxiosResponse<ApiResponse<Contrat[]>> = await http.get(
      `${contrat}/${id}`
    );
    try {
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async getContrat(
    ids: string | undefined,
    idc: string | undefined
  ): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.downloadDoc'));
    const rs: AxiosResponse<ApiResponse<Contrat[]>> = await http.get(
      `${contrat}/${ids}/${idc}`
    );
    try {
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async refresh(): Promise<Souscription[]> {
    const toastLoading = toast.loading(
      i18next.t('toast.reloadingSouscription')
    );
    try {
      const data: Souscription[] = await this.load();
      toast.dismiss(toastLoading);
      return data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async repopulate(): Promise<Souscription[]> {
    const rs: AxiosResponse<ApiResponse<Souscription[]>> = await http.get(
      `${source}`,
      { params: new URLSearchParams({ long: 'false' }) }
    );
    store.dispatch(setOngoingSouscriptions(rs.data.data));
    store.dispatch(setPraparedSouscriptions(rs.data.data));
    return rs.data.data;
  }

  async initialize(data: SouscriptionSplit[], id: string): Promise<any[]> {
    const toastLoading = toast.loading(i18next.t('toast.initSouscription'));
    try {
      const rs: AxiosResponse<ApiResponse<any[]>> = await http.post(
        `${source}/${id}/initialize`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      await this.refresh();
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async ongoing(id: string): Promise<Souscription | null> {
    let data: Souscription[] = store.getState().souscription.ongoing;
    if (data.length <= 0) {
      data = await this.load();
    }

    const isInOngoing = data.some((souscription) => souscription.id === id);

    if (!isInOngoing) {
      throw 'Souscription introuvable';
    }

    return data.find((souscription) => souscription.id === id) || null;
  }

  async prepared(id: string): Promise<Souscription | null> {
    let data: Souscription[] = store.getState().souscription.prepared;
    if (data.length <= 0) {
      data = await this.load();
    }

    const isInPrepared = data.some((souscription) => souscription.id === id);

    if (!isInPrepared) {
      throw 'Souscription introuvable';
    }

    return data.find((souscription) => souscription.id === id) || null;
  }

  async preOnboarding(id: string): Promise<SouscriptionForm> {
    const toastLoading = toast.loading(i18next.t('toast.preOnbSouscription'));
    try {
      const rs: AxiosResponse<ApiResponse<SouscriptionForm>> = await http.get(
        `${source}/${id}/pre-onboarding`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      // const err = error as AxiosError<ApiResponse>;
      // toast.error(err.response?.data.message ?? '', {
      //     id: toastLoading
      // });
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async onboarding(id: string): Promise<SouscriptionForm> {
    const toastLoading = toast.loading(i18next.t('toast.kycSouscription'));
    try {
      const rs: AxiosResponse<ApiResponse<SouscriptionForm>> = await http.get(
        `${source}/${id}/onboarding`
      );
      toast.dismiss(toastLoading);
      return rs.data.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async store(data: any, id?: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.saveSouscription'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `${source}/${id}`,
        data
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });

      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async deleteDocument(id: string): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.deleteDoc'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.delete(
        `documents/${id}`
      );
      toast.success(i18next.t(`serverMessages.${rs.data.message}`), {
        id: toastLoading
      });
      this.repopulate();
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }

  async validedFolder(id: string, data: any): Promise<any> {
    const toastLoading = toast.loading(i18next.t('toast.saveSouscription'));
    try {
      const rs: AxiosResponse<ApiResponse> = await http.post(
        `documents/valid/${id}`,
        data
      );

      const info = rs.data;

      if (info.data.status) {
        toast.dismiss(toastLoading);
      } else {
        if (info.data === 'wrong subscription status') {
          toast.success(i18next.t('toast.validateKyc'), {
            id: toastLoading
          });
        }
      }
      this.repopulate();
      return rs.data;
    } catch (error) {
      toast.dismiss(toastLoading);
      throw error;
    }
  }
}

export default new SouscriptionService();
