import { createSlice } from '@reduxjs/toolkit';

import { Document } from '@models/Souscription';

type DocumentType = {
  allDoc: Document[];
  docReject: Document[];
  docATelecharger: Document[];
  docAConfirm: Document[];
  docValider: Document[];
  docEnAttente: Document[];
};

const initialState: DocumentType = {
  allDoc: [],
  docReject: [],
  docATelecharger: [],
  docAConfirm: [],
  docValider: [],
  docEnAttente: []
};

const documentReducer = createSlice({
  name: 'document',
  initialState,
  reducers: {
    getDocuments: (state) => {
      return state;
    },
    setDocuments: (state, action) => {
      // on garde tous les documents
      state.allDoc = action.payload;

      // on garde les document a telecharger
      const documentAtelecharger = action.payload.filter(
        (doc: Document) =>
          (doc.suggested_document === null || !doc.suggested_document) &&
          doc.status === undefined
      );
      state.docATelecharger = documentAtelecharger;

      // on garde les document en attente
      const documentEnAttente = action.payload.filter(
        (doc: Document) => doc.status === 0
      );
      state.docEnAttente = documentEnAttente;

      // on garde les document a confirmer
      const documentAConfirmer = action.payload.filter(
        (doc: Document) =>
          doc.suggested_document && doc.suggested_document !== null
      );
      state.docAConfirm = documentAConfirmer;

      // on garde les document rejeter
      const documentRejeter = action.payload.filter(
        (doc: Document) => doc.status === -1
      );
      state.docReject = documentRejeter;

      // on garde les document valider
      const documentValider = action.payload.filter(
        (doc: Document) => doc.status === 1
      );
      state.docValider = documentValider;
    }
  }
});

export const { getDocuments, setDocuments } = documentReducer.actions;

export default documentReducer.reducer;
